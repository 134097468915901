<template>
  <div v-if="success" :class="$style.container">
    <SharedLoginContextSelection
      v-if="$access.global.read || $access.global.write || locations.length"
      :locations="locations"
    />
    <SharedLoginEmptyContext v-else @retry="identityService.logout" />
  </div>
  <SharedLoginEntryForm v-else @success="onSuccess" />
</template>

<script lang="ts" setup>
import type { DtoLocation } from '~/services/sdk/api'

definePageMeta({
  layout: 'public',
  public: true,
  pageTransition: { name: 'page', mode: 'out-in' },
})

const { $access } = useNuxtApp()

const identityService = useIdentityService()

const success = ref<boolean>(false)

const locations = ref<DtoLocation[]>([])

const onSuccess = (result: DtoLocation[]): void => {
  locations.value = result
  success.value = true
}
</script>

<style lang="scss" module>
.container {
  display: grid;
  gap: 32px;
}
</style>
