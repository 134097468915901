<template>
  <form novalidate :class="$style.form" @submit.prevent="submit">
    <div :class="$style.header">
      <h1 :class="[$style.title, 'title-md']">
        {{ $t('login.title') }}
      </h1>
      <UiAlertSection v-if="error" ui="danger">
        {{ error }}
      </UiAlertSection>
    </div>
    <div :class="$style.container">
      <UiFormField
        v-slot="{ invalid }"
        :label="$t('field.email.label')"
        :error="email.data.validationError"
      >
        <UiFormInput
          v-model="email.data.value"
          type="email"
          :invalid="invalid"
        />
      </UiFormField>
      <UiFormField
        v-slot="{ invalid }"
        :label="$t('field.password.label')"
        :error="password.data.validationError"
      >
        <UiFormInput
          v-model="password.data.value"
          type="password"
          :invalid="invalid"
        />
      </UiFormField>
      <div :class="$style.link">
        <NuxtLink :to="$link.forgotPassword()">
          {{ $t('login.forgotPassword') }}
        </NuxtLink>
      </div>
    </div>
    <UiBaseButton
      type="submit"
      :disabled="disabled"
      :loading="processing"
      :class="$style.submit"
    >
      {{ $t('login.submit') }}
    </UiBaseButton>
  </form>
</template>

<script lang="ts" setup>
const __MACROS_emit = defineEmits(["success"])

import type { DtoLocation } from '~/services/sdk/api'

const success = (...args) => __MACROS_emit("success", ...args)

const { $link } = useNuxtApp()

const router = useRouter()

const route = useRoute()

const identityService = useIdentityService()

const email = useEmailField({ required: true })

const password = useStringField({ required: true })

const disabled = computed<boolean>(
  () => !email.data.trimmedValue.length || !password.data.trimmedValue.length,
)

const {
  processing,
  error,
  action: submit,
} = useRequestWrapper(async (api) => {
  const isValidEmail = email.actions.validate(email.data.trimmedValue)
  const isValidPassword = password.actions.validate(password.data.trimmedValue)

  if (!isValidEmail || !isValidPassword) return

  await identityService.login({
    username: email.data.trimmedValue,
    password: password.data.trimmedValue,
  })

  if (typeof route.query.returnUrl === 'string') {
    router.push(route.query.returnUrl)
    return
  }

  await identityService.fetchUserDetails()

  const locations = await api.locations.getLocations()

  success(locations)
})
</script>

<style lang="scss" module>
.form {
  position: relative;
  display: grid;
  gap: 48px;
}

.header {
  display: grid;
  gap: 16px;
}

.title {
  color: var(--text-dark-color);
}

.container {
  display: grid;
  gap: 24px;
}

.submit {
  min-width: 160px;
  margin-inline: auto;
}

.link {
  margin-top: -10px;
  text-align: right;
}
</style>
