<template>
  <h1 class="title-md">
    {{ $t('context.select') }}
  </h1>
  <div :class="$style.options">
    <NuxtLink
      v-if="$access.global.read || $access.global.write"
      :to="$link.index()"
      :class="$style.option"
    >
      <div :class="$style.subtitle">
        {{ $t('context.global') }}
      </div>
    </NuxtLink>
    <NuxtLink
      v-for="location in locations"
      :key="location.id"
      :to="$link.context.dashboard(location.id)"
      :class="$style.option"
    >
      <div :class="$style.subtitle">
        {{ location.name }}
      </div>
      <div v-if="location.address" :class="$style.meta">
        {{ location.address }}
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { DtoLocation } from '~/services/sdk/api'

defineProps({
  locations: null
})

const { $access } = useNuxtApp()
</script>

<style lang="scss" module>
.options {
  display: grid;
  gap: 16px;
}

.option {
  display: block;
  color: var(--text-dark-color);
  transition: color 0.3s;

  @include interact {
    color: var(--accent-color);
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
}

.meta {
  color: var(--meta-color);
  font-size: 12px;
  line-height: 14px;
}
</style>
