<template>
  <h1 class="title-md">
    {{ $t('context.forbidden') }}
  </h1>
  <button type="button" :class="$style.button" @click="retry">
    {{ $t('context.retry') }}
  </button>
</template>

<script lang="ts" setup>
const __MACROS_emit = defineEmits(["retry"])

const retry = (...args) => __MACROS_emit("retry", ...args)
</script>

<style lang="scss" module>
.button {
  color: var(--accent-color);
  font-size: 16px;
  line-height: 1.3;

  @include interact {
    text-decoration: underline;
  }
}
</style>
